import { Application } from '@splinetool/runtime';
import * as THREE from 'three';

window.THREE = THREE;

let app;
let isSplineInitialized = false;
let currentSplineVersion = '';

const DESKTOP_SPLINE_URL = 'https://prod.spline.design/jOQmpTI2bETGijyl/scene.splinecode';
const MOBILE_SPLINE_URL = 'https://prod.spline.design/gVsruay7Ms9AAs4N/scene.splinecode';  

async function initializeSpline() {
  const isMobile = window.innerWidth < 768;
  const splineUrl = isMobile ? MOBILE_SPLINE_URL : DESKTOP_SPLINE_URL;

  if (isSplineInitialized && currentSplineVersion === splineUrl) {
      console.log('Correct Spline version already initialized');
      return;
  }

  const canvas = document.getElementById('spline-canvas');
  if (!canvas) {
      console.error('Spline canvas not found');
      return;
  }

  if (app) {
      app.dispose();
  }

  app = new Application(canvas);

  try {
      await app.load(splineUrl);
      console.log(`Spline scene loaded successfully: ${isMobile ? 'mobile' : 'desktop'} version`);
      isSplineInitialized = true;
      currentSplineVersion = splineUrl;
      resizeSpline();
  } catch (error) {
      console.error('Error loading Spline scene:', error);
  }
}

function resizeSpline() {
  if (app) {
      const container = document.getElementById('spline-container');
      if (container) {
          const width = container.clientWidth;
          const height = container.clientHeight;
          app.setSize(width, height);
          
          // Check if we need to switch Spline versions
          const shouldBeMobile = width < 768;
          if ((shouldBeMobile && currentSplineVersion !== MOBILE_SPLINE_URL) ||
              (!shouldBeMobile && currentSplineVersion !== DESKTOP_SPLINE_URL)) {
              initializeSpline();
              return;
          }
          
          // Adjust camera based on screen size
          const camera = app.camera;
          if (camera) {
              let cameraZ, fov;
              if (width < 576) { // Extra small devices
                  cameraZ = 12;
                  fov = 80;
              } else if (width < 768) { // Small devices
                  cameraZ = 10;
                  fov = 75;
              } else if (width < 992) { // Medium devices
                  cameraZ = 8;
                  fov = 70;
              } else if (width < 1200) { // Large devices
                  cameraZ = 6;
                  fov = 65;
              } else { // Extra large devices
                  cameraZ = 5;
                  fov = 60;
              }
              
              camera.position.set(0, 0, cameraZ);
              camera.fov = fov;
              camera.aspect = width / height;
              camera.updateProjectionMatrix();
          }
          
          console.log('Spline resized to:', width, 'x', height);
      }
  }
}

function debounce(func, wait) {
  let timeout;
  return function executedFunction(...args) {
      const later = () => {
          clearTimeout(timeout);
          func(...args);
      };
      clearTimeout(timeout);
      timeout = setTimeout(later, wait);
  };
}

window.addEventListener('resize', debounce(resizeSpline, 250));

function reinitializeSpline() {
    if (app) {
        app.dispose();
    }
    isSplineInitialized = false;
    initializeSpline();
}

function scrollToGrid() {
    const gridSection = document.getElementById('grid');
    if (gridSection) {
        gridSection.scrollIntoView({ behavior: 'smooth' });
    }
}

function clearUrlHash() {
    history.pushState("", document.title, window.location.pathname + window.location.search);
}

function handleNavigation() {
    const hash = window.location.hash;
    if (hash === '#grid') {
        setTimeout(() => {
            scrollToGrid();
            clearUrlHash(); // Remove the hash after scrolling
        }, 100);
    }
    
    if (!isSplineInitialized) {
        initializeSpline();
    } else {
        reinitializeSpline();
    }
}

window.addEventListener('pageshow', function(event) {
    if (event.persisted) {
        handleNavigation();
    }
});

window.addEventListener('popstate', handleNavigation);

function updateHomeLinks() {
    const homeLinks = document.querySelectorAll('a[href$="index.html#grid"]');
    homeLinks.forEach(link => {
        link.setAttribute('href', 'index.html');
        link.addEventListener('click', (e) => {
            e.preventDefault();
            window.location.href = 'index.html';
            setTimeout(scrollToGrid, 100);
        });
    });
}

function handleCardClick(e) {
    e.preventDefault();
    const card = e.currentTarget;
    const action = card.getAttribute('data-action');
    const url = card.getAttribute('data-url');
    console.log('Click en tarjeta:', card);
    console.log('Action:', action, 'URL:', url);

    if (action === 'page' && url) {
        console.log('Intentando navegar a:', url);
        window.location.href = url;
    } else if (action === 'popup') {
        showPopup(card);
    } else {
        console.log('No se pudo navegar. Action o URL no válidos');
    }
}

function initializeCardHandlers() {
    const cards = document.querySelectorAll('.card');
    console.log('Número de tarjetas encontradas:', cards.length);
    
    cards.forEach((card, index) => {
        console.log(`Tarjeta ${index + 1}:`, card);
        card.addEventListener('click', handleCardClick);
    });
}

const popup = document.getElementById('imagePopup');
const popupContent = popup ? popup.querySelector('.popup-content') : null;

function showPopup(card) {
    if (popup && popupContent) {
        const imageUrl = card.querySelector('img').src;
        const title = card.querySelector('.overlay h3').textContent;
        
        popupContent.innerHTML = `
            <img src="${imageUrl}" alt="${title}">
            <h3>${title}</h3>
            <button class="close">&times;</button>
        `;
        
        popup.style.display = 'flex';
        document.body.style.overflow = 'hidden'; // Previene el scroll detrás del popup
        
        const closeButton = popupContent.querySelector('.close');
        if (closeButton) {
            closeButton.addEventListener('click', closePopup);
        }
    }
}

function closePopup() {
    if (popup) {
        popup.style.display = 'none';
        document.body.style.overflow = 'auto'; // Restaura el scroll
        
        // Elimina cualquier elemento residual del popup
        const overlay = document.querySelector('.popup-overlay');
        if (overlay) {
            overlay.remove();
        }
    }
}

if (popup) {
    popup.addEventListener('click', (e) => {
        if (e.target === popup) {
            closePopup();
        }
    });
}

// Close popup when loading a new page
window.addEventListener('pageshow', (event) => {
    if (event.persisted) {
        closePopup();
    }
});

document.addEventListener('DOMContentLoaded', () => {
  updateHomeLinks();
  handleNavigation();
  closePopup();
  initializeCardHandlers();
  initializeSpline();
  console.log('DOMContentLoaded event fired');
});

// Utility function for debugging
window.logSplineSize = function() {
    if (app && app.canvas) {
        console.log('Spline canvas size:', app.canvas.width, app.canvas.height);
    } else {
        console.log('Spline app or canvas not initialized');
    }
};

// Expose reinitializeSpline to global scope for manual triggering if needed
window.reinitializeSpline = reinitializeSpline;